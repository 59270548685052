@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor {
  min-height: 250px;
  background-color: white;
}
.anticon svg {
  display: block;
}
.ant-card-type-inner .ant-card-head-title {
  font-size: 15px;
  font-weight: 600;
  color: #9d7c38;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.signatureCanvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
}

.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.container {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: white !important;
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 18px !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 4px;
  background: white;
}

@primary-color: #9d7c38;